import { createStore } from "vuex";
import axios from "axios";
import { useStammdatenStore } from "@/store/stammdaten";
import {setSupabaseSession} from "@/service/supabase";

const store = createStore({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: localStorage.getItem("user") || "johndoe",
    xpuser: localStorage.getItem("xpuser") || "",
    modules: localStorage.getItem("modules") || "",
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token.token;
      state.user = token.user;
      state.xpuser = token.xpuser;
    },
    auth_error(state) {
      state.status = "error";
    },
    auth_modules(state, modules) {
      state.modules = modules;
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.modules = "";
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: process.env.VUE_APP_API_URL + "/login",
          data: user,
          method: "POST" /* TODO: auskommentieren bei Legacy,*/,
          withCredentials: true,
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem("token", JSON.stringify(token));
            localStorage.setItem("user", user.name);
            localStorage.setItem("xpuser", user.xpuser);
            setSupabaseSession();
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", {
              token: token,
              user: user.name,
              xpuser: user.xpuser,
            });
            resolve(resp);

            axios({
              url: process.env.VUE_APP_API_URL + "/usermodul/" + user.id,
              method: "GET",
            }).then((r) => {
              localStorage.setItem("modules", r.data);
              commit("auth_modules", r.data);
            });
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    checklogin({ commit }) {
      console.log('checklogin')
      /*supabase.auth.getUser().then((user) => {
        if (user.error) {
          commit("auth_error");
          dispatch("logout");
          localStorage.removeItem("token");
        }
      }).catch((error) => {
        console.error('error', error)
      })*/
      axios({
        url: process.env.VUE_APP_API_URL + "/user",
        method: "GET",
      }).catch(() => {
        commit("auth_error");
        localStorage.removeItem("token");
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: process.env.VUE_APP_API_URL + "/register",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            console.log("resp", resp);
            resolve(resp);
          })
          .catch((err) => {
            console.log("err", err);
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        axios
          .get(process.env.VUE_APP_API_URL + "/logout")
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("modules");
            delete axios.defaults.headers.common["Authorization"];
            const store = useStammdatenStore();
            store.unsubscribeFromAllChannels();
            resolve();
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
    xpuser: (state) => state.xpuser,
    modules: (state) => state.modules,
  },
});

export default store;
